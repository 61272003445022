var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-app", { staticClass: "day-object-viewer" }, [
    _c("div", { staticClass: "plans" }, [
      _c(
        "div",
        { staticClass: "plan-box-vertical" },
        [
          _c("div", { staticClass: "plan-header-vertical" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "day-header-item" },
                [_c("day-header", { attrs: { day: _vm.currentDay } })],
                1
              ),
            ]),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "day-header-count-item",
                  staticStyle: { width: "auto" },
                },
                [
                  _c("day-count", {
                    attrs: {
                      day: _vm.currentDay,
                      dayString: _vm.currentDay.dayString,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("section-card", {
            attrs: { dayObject: _vm.currentDayObject, showDetails: true },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }