






















import SectionCard from '@/components/plans/card/SectionCard.vue';
import DayHeader from '@/components/plans/common/DayHeader.vue';
import DayCount from '@/components/plans/common/DayCount.vue';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone';
import SettingsServices from '@/services/settings-services';

const plans = namespace('plans');
const settings = namespace('settings');

@Component({
  components: {
    SectionCard,
    DayHeader,
    DayCount
  }
})
export default class DayObjectViewer extends Vue {
  date = '';
  classId = 0;
  extraLessonId = 0;
  eventId = 0;
  yearId = 0;
  teacherId = 0;

  @plans.Mutation
  setMonday!: (monday: string) => void;

  @plans.Mutation
  setCurrentDay!: (monday: string) => void;

  @plans.Mutation
  setMonth!: (monday: string) => void;

  @plans.Action
  init!: (param?: any) => Promise<any>;

  @plans.Action('fetchPlans')
  loadPlans!: (param?: any) => Promise<any>;

  @plans.Getter('getCurrentPlans')
  currentPlans!: any;

  @settings.Getter('getCurrentSchoolYearId')
  currentSchoolYearId!: number;

  @settings.Mutation
  setUserMode!: (userMode: string) => void;

  get currentDay() {
    for (const key in this.currentPlans) {
      if (key.startsWith('day')) {
        const day = this.currentPlans[key];
        if (day && day.date === this.date) {
          day.dayString = key;
          return day;
        }
      }
    }
    return { dayObjects: [], dayString: '' }
  }

  get currentDayObjects(): Array<any> {
    return this.currentDay.dayObjects;
  }

  get currentDayObject() {
    return this.currentDayObjects.find(d => {
      const rawDayObject = d.rawDayObject;
      if (d.type === 'L' && this.classId > 0) {
        return rawDayObject.extraLesson === this.extraLessonId && rawDayObject.classId === this.classId;
      } else if (d.type === 'E' && this.eventId > 0) {
        return rawDayObject.eventId === this.eventId;
      }
      return false;
    });
  }

  async fetchPlans() {
    CommonUtils.showLoading();
    if (this.$currentUser.isDualUser) {
      this.setUserMode('T');
    }
    return Promise.resolve().then(() => {
      if (this.yearId > 0 && this.currentSchoolYearId !== this.yearId && this.$currentUser.isTeacher) {
        return SettingsServices.changeTerm({ yearId: this.yearId })
      } else {
        return Promise.resolve({});
      }
    })
      .then(this.init)
      .then(this.loadPlans)
      .finally(CommonUtils.hideLoading);
  }

  mounted() {
    this.fetchPlans();
  }

  created() {
    let date = moment(this.$route.query.d as string, 'YYYYMMDD');
    if (!date.isValid()) {
      date = moment();
    }
    this.date = date.format('MM/DD/YYYY');
    this.extraLessonId = +this.$route.query.el || 0;
    this.classId = +this.$route.query.c || 0;
    this.eventId = +this.$route.query.e || 0;
    this.yearId = +this.$route.query.y || 0;
    this.teacherId = +this.$route.query.t || 0;
    this.setCurrentDay(date.format('MM/DD/YYYY'));
    this.setMonday(date.startOf('isoWeek').format('MM/DD/YYYY'));
    this.setMonth(date.startOf('month').format('MM/DD/YYYY'));
  }
}
